import logo from '../src/assets/images/andoidnelonlogo2.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaArrowDown } from 'react-icons/fa';
import { FaLinkedin, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';

function App() {
  return (
    <Container fluid className="App">
      <Row className="App-header" style={{ 
        backgroundImage: `url(${logo})`, 
        backgroundPosition: 'center', // centraliza a imagem
        backgroundRepeat: 'no-repeat', // impede que a imagem se repita
        backgroundSize: 'cover', // faz com que a imagem cubra todo o espaço disponível
        opacity: 0.8 
      }}>
        <Col xs={12}>
          <h1>Android Neon</h1>
          <FaArrowDown />
        </Col>
      </Row>
      <Row className="App-footer">
        <Col xs={12}>
          <p>Prototipos de Nello Orsolon</p>
          <a href="https://www.linkedin.com/in/nello-orsolon-8a739137/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}><FaLinkedin size={60} /></a>
          <a href="https://twitter.com/nelloe88" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}><FaTwitter size={60} /></a>
          <a href="https://www.youtube.com/channel/UCDgCWhf3iDNI1bMtpcxtUbQ" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}><FaYoutube size={60} /></a>
          <a href="https://www.instagram.com/androidneon.nello/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}><FaInstagram size={60} /></a>
        </Col>
      </Row>
      <Row className="App-footer">
        <Col xs={12}>
          <p><i>Desenvolvido por Nello Orsolon</i></p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;